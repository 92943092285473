import React, { createContext, useContext, ReactNode, useState } from 'react';
import "../styles/popup.css";
import IconClose from "../images/icons/close.svg"

interface PopupMessage {
    heading: string;
    content: React.ReactNode;
    isFilledBg?: boolean;
}

interface PopupWithContentContextProps {
    showMessageWithContent: (heading: string, content: React.ReactNode, isFilledBg?: boolean) => void;
    closeMessage: () => void;
    messages: PopupMessage[];
}

const PopupContext = createContext<PopupWithContentContextProps | undefined>(undefined);

interface PopupProviderProps {
    children: ReactNode;
}

export const PopupWithContentProvider: React.FC<PopupProviderProps> = ({ children }) => {
    const [messages, setMessages] = useState<PopupMessage[]>([]);

    const showMessageWithContent = (heading: string, content: React.ReactNode, isFilledBg?: boolean) => {
        setMessages(prevMessages => [...prevMessages, { heading, content, isFilledBg }]);
    };

    const closeMessage = () => {
        setMessages(prevMessages => prevMessages.slice(0, -1));
    };

    return (
        <PopupContext.Provider value={{ showMessageWithContent, closeMessage, messages }}>
            {children}
            {messages.map((message, index) => (
                <PopupWithContent key={index} heading={message.heading} content={message.content} isFilledBg={message.isFilledBg} />
            ))}
        </PopupContext.Provider>
    );
};

export const usePopupWithContent = () => {
    const context = useContext(PopupContext);

    if (!context) {
        throw new Error('usePopup must be used within a PopupProvider');
    }

    return context;
};

const PopupWithContent: React.FC<{ heading: string; content: React.ReactNode; isFilledBg?: boolean }> = ({ heading, content, isFilledBg }) => {
    const { closeMessage } = usePopupWithContent();
    return (
        <div className={"popupWithContent"}>
            <div className={"popupBackground"} onClick={closeMessage}></div>
            <div className={`popupContentWithComponent ${isFilledBg ? "isFilledBg" : ""}`}>
                <div className={"popupContainer"}>
                    <div className={"popupHeader"}>
                        <div className={"popupHeading"}>{heading}</div>
                        <div onClick={closeMessage} className={"popupClose"}>
                            <img className={""} src={IconClose}/>
                        </div>
                    </div>
                    <div className={"popupMessageWithComponent"}>{content}</div>
                </div>
            </div>
        </div>
    );
};
