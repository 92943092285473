import React from 'react';
import Catalog from "./components/Catalog";
import {PopupWithContentProvider} from "./components/PopupWithContent";

function App() {
  return (
      <PopupWithContentProvider>
          <div className="App">
            <Catalog/>
          </div>
      </PopupWithContentProvider>

  );
}

export default App;
